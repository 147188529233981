import React, { useState, useEffect } from "react";
import WheelComponent from "react-wheel-of-prizes";

import roulette from "../components/images/roulette.png";
import onetotwentyfive from "../components/images/1to25.png";
import colors from "../components/images/colors.png";
import traits from "../components/images/traits.png";

function Wheel() {
  const [ready, setReady] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [segments, setSegments] = useState([]);
  const segColors = [
    "#EE4040",
    "#F0CF50",
    "#815CD1",
    "#3DA5E0",
    "#1A1A1A",
    '#FF6633', '#FFB399',   '#00B3E6', 
    '#E6B333', '#3366E6', '#999966',  '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', 
    '#FF99E6', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', 
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', 
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
     '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', 
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'
  ];
  const onFinished = (winner) => {
    console.log(winner);
  };

  const addWord = (e) => {
    setReady(false);
    if (e.key === "Enter") {
      setSegments([...segments, e.target.value]);
      setInputValue("");
      console.log(segments);
    }
  };

  const readyOrNot = () => {
    setReady(true);
  };

  return (
    <div id="wheelCircle">
      <div className="container">
        <div className="controls">
          <input
            type="text"
            onKeyDown={(e) => addWord(e)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Enter a new word and press enter"
          />
          <button className="ready-btn" onClick={readyOrNot}>
            Ready
          </button>
          <div>
            {segments.map((seg) => (
              <div className="segment-names">
                <button>x</button>
                <li>{seg}</li>
              </div>
            ))}
          </div>
        </div>
        <div className="wheel">
          {ready && (
            <WheelComponent
              segments={segments}
              segColors={segColors}
              winningSegment=""
              onFinished={(winner) => onFinished(winner)}
              primaryColor="black"
              primaryColoraround="#ffffffb4"
              contrastColor="white"
              buttonText="Spin"
              isOnlyOnce={false}
              size={290}
              upDuration={150}
              downDuration={1000}
              width={(60 * window.innerWidth) / 100}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Wheel;
