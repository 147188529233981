import React, { useEffect, useState } from "react";
import Wheel from "./components/Wheel";
import "../src/App.css";

export default function App() {
  
  return (
    <>
      <Wheel />
    </>
  );
}
